import REGEX from '@/utils/config/regex'

export const pathIsValid = (path) => REGEX.CREDENTIAL_PATH.test(path)

export const generalCredentials = ['basic_auth', 'custom', 'ssh']
export const vendorCredentials = ['aws', 'azure', 'azure_storage', 'gcp', 'elasticsearch', 'swift', 'vmware']

export const CREDENTIAL_DEFAULTS = {
  aws: {
    access_key: '',
    secret_key: '',
  },
  azure: {
    client_id: '',
    client_secret: '',
    subscription_id: '',
    tenant_id: '',
  },
  azure_storage: {
    access_key: '',
    account_name: '',
  },
  basic_auth: {
    password: '',
    username: '',
  },
  custom: [],
  elasticsearch: {
    ca_cert: '',
    password: '',
    username: '',
  },
  gcp: {
    json_key: '',
  },
  ssh: {
    ssh_key: '',
  },
  swift: {
    auth_url: '',
    domain_id: '',
    password: '',
    tenant_id: '',
    username: '',
  },
  vmware: {
    password: '',
    username: '',
  },
}
